<template>
  <div class="progress-bar">
    <div class="progress-bar__value" :style="`width: ${pourcent}%;`"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: { type: Number, required: true },
    total: { type: Number, required: true },
  },
  computed: {
    pourcent() {
      return (this.value / this.total) * 100
    },
  },
}
</script>

<style lang="scss">
.progress-bar {
  position: relative;
  min-width: rem(100px);
  height: rem(6px);
  background: var(--secondary-color-4);

  &__value {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: var(--secondary-color);
    max-width: 100%;
    will-change: width;
    transition: width 0.3s var(--asphalte-animation-function);
  }
}
</style>
