export const EVENTS = {
  ASK_NOTIFICATION: 'ask_for_notification_register',
  CLOSE_CART: 'close-cart',
  PRODUCT_UPDATED: 'products-updated',
  PRODUCT_VARIANT_UPDATED: 'product-variant-updated',
  SHOW_FORCE_CONNECT: 'show-force-connect',
  USER_STATE_LOADED: 'user-state-loaded',
  USER_LOGGED_IN: 'user-has-logged-in',
  USER_LOGGED_OUT: 'user-has-logged-out',
}
