<template>
  <div
    class="rich-text"
    :class="{ 'no-center': noCenter, 'no-max-width': noMaxWidth }"
    v-html="prismicContent"
  ></div>
</template>

<script>
import * as prismic from '@prismicio/client'
import lozad from 'lozad-new'

import htmlSerializer from '@/plugins/prismic/html-serializer'

export default {
  name: 'RichText',
  props: {
    content: {
      type: Array,
      required: true,
    },
    noCenter: { type: Boolean, default: false },
    noMaxWidth: { type: Boolean, default: false },
  },
  computed: {
    prismicContent() {
      return prismic.asHTML(this.content, { serializer: this.htmlSerializer })
    },
  },
  async mounted() {
    const tooltips = this.$el.getElementsByClassName('tooltip')

    if (this.$el.getElementsByClassName('rich-text__lazy').length > 0) {
      for (const img of this.$el.getElementsByClassName('rich-text__lazy')) {
        const observer = lozad(img, {
          loaded: (el) => {
            el.classList.add('loaded')
          },
        })
        observer.observe()
      }
    }

    if (tooltips.length > 0) {
      const tippy = await import('tippy.js' /* webpackChunkName: 'tippy' */)
      await import(
        '@/scss/09-libs/_06-tippy.scss' /* webpackChunkName: 'tippy_css' */
      )
      await import(
        'tippy.js/animations/scale.css' /* webpackChunkName: 'tippy_animation_scale_css' */
      )

      for (const tooltip of tooltips) {
        tippy.default(tooltip, {
          theme: 'dark',
          placement: 'top',
          animation: 'scale',
          content: this.$t('loading'),
          arrow: true,
          onShow: (instance) => {
            const uid = this.removeDiacritics(instance.reference.innerText)
              .toLowerCase()
              .trim()
              .replaceAll(' ', '-')
            this.$prismic.client
              .getByUID('bibliographie', uid, { lang: this.$i18n.locale })
              .then((document) => {
                if (document) {
                  instance.setContent(document.data.explication[0].text)
                } else {
                  instance.setContent(this.$t('request.no-result'))
                }
              })
              .catch((err) => {
                instance.setContent(this.$t('request.failed'))
                this.$logError.captureException(err)
              })
          },
        })
      }
    }
  },
  methods: {
    htmlSerializer(type, element, content) {
      return htmlSerializer(type, element, content, this.$contextPath)
    },
    removeDiacritics(s) {
      return s.normalize('NFD').replace(/\p{Diacritic}/gu, '')
    },
  },
}
</script>

<style lang="scss">
.rich-text {
  max-width: rem(720px);
  margin-left: auto;
  margin-right: auto;

  &.no-center {
    margin-left: 0;
    margin-right: 0;
  }

  &.no-max-width {
    max-width: none;
  }

  .lazy-image-container {
    max-width: 100%;
  }

  p,
  > .lazy-image-container,
  ul {
    margin-bottom: calc(var(--spacing) * 2);
  }

  p {
    + ul {
      margin-top: calc(var(--spacing) * -1);
    }
  }

  a {
    text-decoration: underline;

    @include on-hover-and-focus {
      text-decoration: none;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .left-align,
  .center-align,
  .right-align {
    display: block;
  }

  .left-align {
    text-align: left;
  }

  .center-align {
    text-align: center;
  }

  .right-align {
    text-align: right;
  }

  h2,
  h3,
  h4 {
    @include mq($from: tablet) {
      margin-bottom: rem($spacing * 3);

      &:not(:first-child) {
        margin-top: rem($spacing * 3);
      }
    }
  }
}

.tooltip {
  cursor: help;

  &::after {
    content: '';
    background: url('~/assets/img/info.svg') no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    width: rem(14px);
    height: rem(14px);
    margin-left: rem(4px);
    position: relative;
    top: rem(2px);
  }

  &--sun {
    &::after {
      background-image: url('~/assets/img/info-red.svg');
    }
  }
}
</style>
