// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/info.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/info-red.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rich-text{margin-left:auto;margin-right:auto;max-width:45rem}.rich-text.no-center{margin-left:0;margin-right:0}.rich-text.no-max-width{max-width:none}.rich-text .lazy-image-container{max-width:100%}.rich-text p,.rich-text ul,.rich-text>.lazy-image-container{margin-bottom:calc(var(--spacing)*2)}.rich-text p+ul{margin-top:calc(var(--spacing)*-1)}.rich-text a{-webkit-text-decoration:underline;text-decoration:underline}@media (hover:hover) and (pointer:fine){.rich-text a:hover{-webkit-text-decoration:none;text-decoration:none}}.rich-text a:focus-visible{-webkit-text-decoration:none;text-decoration:none}.rich-text>:last-child{margin-bottom:0}.rich-text .center-align,.rich-text .left-align,.rich-text .right-align{display:block}.rich-text .left-align{text-align:left}.rich-text .center-align{text-align:center}.rich-text .right-align{text-align:right}@media (min-width:48.0625em){.rich-text h2,.rich-text h3,.rich-text h4{margin-bottom:2.8125rem}.rich-text h2:not(:first-child),.rich-text h3:not(:first-child),.rich-text h4:not(:first-child){margin-top:2.8125rem}}.tooltip{cursor:help}.tooltip:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;content:\"\";display:inline-block;height:.875rem;margin-left:.25rem;position:relative;top:.125rem;width:.875rem}.tooltip--sun:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
